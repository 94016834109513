<template>
  <footer>
	<div class="section-in">
		<div class="inner_footer">
			<div class="footer_cont">
				<select onchange="if(this.value) window.open(this.value);">
          <option selected="selected" value="">FAMILY SITE</option>
          <option value=" //etners.com/">ETNERS</option>
          <option value="//sharedservice.etners.com">SHARED SERVICE</option>
          <option value="//etnerspayroll.com">PAYROLL</option>
          <option value="//gamdongtime.etnersplatform.com/">GAMDONG TIME</option>
          <option value="//esop.etners.com">ESOP</option>
          <option value="//mall.etners.com">MALL</option>
          <option value="//moving.etners.com">MOVING</option>
          <option value="//housing.etners.com">HOUSING</option>
          <option value="//relocation.etners.com">RELOCATION</option>
          <option value="//bidding.etners.com/">BIDDING</option>
          <option value="//shop.etners.com">SHOP</option>
          <option value="//etnersefs.com/landing">EFS</option>
          <option value="//esrm.etners.com">ESRM</option>
				</select>
			</div>
			<div class="footer_info">
				<div class="footer_logo_wrap">
					<img src="https://single.etnersplatform.com/files/202108059000137" alt="footer_logo">
				</div>
				<div>
					<a href="https://etners.com/terms/management/detail/10/02" target="_blank" class="link_info">
						<b>개인정보처리방침</b></a><span class="txt_bar"> | </span><a href="https://etners.com/terms/management/detail/10/01" class="link_info"><b>이용약관</b></a>
				</div>
				[13840] 경기도 과천시 과천대로7나길 60 과천어반허브 A동 5F<br>
				대표전화 : 1533-4810 팩스 : 070-8806-5190<!--개인정보처리방침 법적고지 -->
				<small class="txt_copyright">Copyright © etners Corp. All rights reserved.</small>
			</div>
		</div>
		<!--///모바일용 푸터///-->
		<div class="inner_footer_m">
			<div class="footer_info">
				<div class="footer_logo_wrap">
					<img src="https://single.etnersplatform.com/files/202108059000137" alt="footer_logo">
				</div>
				<div class="footer_cont">
					<select onchange="if(this.value) window.open(this.value);">
            <option selected="selected" value="">FAMILY SITE</option>
            <option value=" //etners.com/">ETNERS</option>
            <option value="//sharedservice.etners.com">SHARED SERVICE</option>
            <option value="//etnerspayroll.com">PAYROLL</option>
            <option value="//gamdongtime.etnersplatform.com/">GAMDONG TIME</option>
            <option value="//esop.etners.com">ESOP</option>
            <option value="//mall.etners.com">MALL</option>
            <option value="//moving.etners.com">MOVING</option>
            <option value="//housing.etners.com">HOUSING</option>
            <option value="//relocation.etners.com">RELOCATION</option>
            <option value="//bidding.etners.com/">BIDDING</option>
            <option value="//shop.etners.com">SHOP</option>
            <option value="//etnersefs.com/landing">EFS</option>
            <option value="//esrm.etners.com">ESRM</option>
					</select>
				</div>
				<div>
					<a href="https://etners.com/terms/management/detail/10/02" target="_blank" class="link_info">
						<b>개인정보처리방침</b></a><span class="txt_bar"> | </span><a href="https://etners.com/terms/management/detail/10/01" class="link_info"><b>이용약관</b></a>
				</div>
				[13840] 경기도 과천시 과천대로7나길 60 과천어반허브 A동 5F 대표전화 : 031)628-6200 팩스 : 031)628-6299<!--개인정보처리방침 법적고지 --><br>
				<small class="txt_copyright">Copyright © etners Corp. All rights reserved.</small>
			</div>
		</div>
	</div>
</footer>
</template>

<script>
export default {
  name: 'FooterForLanding',
  methods :{
    popup: function (fileNm) {
      window.open('http://www.etners.com/privacy/etnersefs/fms/' + fileNm + '.html', fileNm)
    },
  }
}
</script>

<style scoped>

</style>
